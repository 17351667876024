import React from "react";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaRedditAlien } from "react-icons/fa";
import { graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Link } from "gatsby";
import { FacebookShareButton, TwitterShareButton, RedditShareButton } from "react-share";
import Seo from '../components/seo';
import * as styles from './BlogPost.module.css';

export const query = graphql`
query PostPageQuery($slug: String!) {
    strapiArticle(slug: {eq: $slug}) {
      strapiId
      slug
      title
      description
      content
      author {
        name
      }
      published_at(formatString: "M/DD/YYYY")
      image {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
  
`

const BlogPost = ({data}) => {
    const article = data.strapiArticle
    const title = article.title
    const url = 'https://maitaisatsunset.com/blog/' + article.slug
    const twitterhandle = 'maitaisatsunset'
    return (
    <>
        <Seo title={title} />
        <Nav />
        <div className="bg-gray-100 min-h-screen">

            <div className="m-auto container md:py-20 py-0">

                    <div className="bg-gray-50 shadow-xl min-h-screen flex flex-col max-w-4xl m-auto md:rounded-xl rounded-none">

                      {/* post heading */}

                        <div className="md:py-10 py-4 space-y-4 md:px-10 px-4">
                          

                          <h1 className="md:text-7xl text-5xl font-semibold">{article.title}</h1>

                          <h3 className="text-3xl italic">{article.description}</h3>

                          <h1 className="text-md">By, {article.author.name} | {article.published_at}</h1>
                        </div>
                        
                      {/* post Image */}

                        <GatsbyImage image={article.image.localFile.childImageSharp.gatsbyImageData} alt={`Picture for ${article.title} article`} className="object-cover" />

                      {/* post Body */}

                        <div className="md:px-10 px-4 md:pb-20 pb-8 md:pt-16 pt-6 md:text-xl text-md max-w-3xl m-auto leading-loose">

                        {/* Share buttons */}

                            <div className="flex flex-row space-x-4 -ml-1 text-2xl items-center md:pb-8 pb-4">


                                <FacebookShareButton url={url}>
                                    <FaFacebookF className="hover:text-pink-400" />
                                </FacebookShareButton>

                                <TwitterShareButton title={title} url={url} via={twitterhandle} >
                                    <FaTwitter className="hover:text-pink-400" />
                                </TwitterShareButton>
                                <RedditShareButton url={url}>
                                    <FaRedditAlien className="hover:text-pink-400" />
                                </RedditShareButton>

                            </div>

                          {/* Content */}
                            <Markdown children={article.content} rehypePlugins={[rehypeRaw]} className={`space-y-5 ${styles.content}`} id="content" />

                        </div>
                    </div>

                </div>
            <Footer />
        </div>
    </>
  )
}

export default BlogPost

